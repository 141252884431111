<template>
  <div v-if="batch" class="row">
    <ConfirmationModal @closemodal="closeConfirmation" @confirmmodal="confirmed" v-show="needsConfirmation" :action="$t(confirmationType)" />
    <BatchOrderModal @confirmation="askConfirmation" @closemodal="item=null" v-if="item" :batch="batch" :product-id="item.getProduct().getId()" />
    <BatchItemDetailsModal :batch="batch" v-if="selectedItem" @closemodal="selectedItem=null" :item="selectedItem" />
    <ShipmentDetailsModal v-if="showShipment" @closemodal="showShipment=false" :shipment="batch.getShipment()" title="deliverynotes.shipments.modal.title_collecting" />
    <div class="col-md-12">
      <Card :title="$t('batches.edit.title', {farmer: batch.getFarm().getName()})">
        <template v-slot:header>
          <div class="row align-items-center justify-content-around">
            <Alert type="light" :styled="false" class="text-center w-100 w-sm-auto">
              <template v-slot:default>
                <Status :status="batch.getStatus()" />
              </template>
            </Alert>
            <ImmutabilityStatus class="w-sm-50" :object="batch" :addition="isEditable" />
          </div>
        </template>
        <template v-slot:default>
          <div class="row text-center">
            <div class="col-md-3" :class="{'col-md-4':!batch.getFarm().getAddress().isSet()}">
              <Call :telephone="batch.getFarm().getTelephone()" :big="true" class="mr-2 d-inline-block" />
              <Notifiable class="d-inline-block" @click="toggleNotify" :big="true" :notifiable="batch.getFarm().canNotify()" :clickable="true" />
              <span class="p-2 d-block">
                <FarmerName :farmer="batch.getFarm()" />
                <span v-show="batch.getFarm().getEmail()" class="d-block">{{ batch.getFarm().getEmail() }}</span>
              </span>
            </div>
            <div v-if="batch.getFarm().getAddress().isSet()" class="col-md-3">
              <Address class="align-self-end" :big="true" :address="batch.getFarm().getAddress()" :responsive="true" />
            </div>
            <div class="col-md-3" :class="{'col-md-4':!batch.getFarm().getAddress().isSet()}">
              <div class="p-2">
                <span class="d-block">{{ $t('batches.edit.total_price_with_vat') }} <Price :amount="batch.getTotalPriceWithVat()" :currency="batch.getCurrency()"/></span>
                <span>{{ $t('batches.edit.total_vat') }} <Price :amount="batch.getTotalVat()" :currency="batch.getCurrency()"/></span>
              </div>
            </div>
            <div class="col-md-3" :class="{'col-md-4':!batch.getFarm().getAddress().isSet()}">
              <div class="p-2">
                <DayTimeEstimation text="batches.edit.delivery" :moment="batch.getCycle().getCollectBatch()" :hours="batch.getCollectingHours()" />
                <span class="d-block">{{$t('batches.edit.weight', {bulk: formatQty(batch.getTotalBulk()), pack: formatQty(batch.getTotalPacked())})}}</span>
                <span class="d-block mt-2"><span>{{$t('batches.edit.farmer_can_deliver')}}</span>
                  <i class="icon-checkmark4 text-success ml-3" v-if="batch.getFarmerCanDeliver()"></i>
                  <i class="icon-cross2 text-danger ml-3" v-if="batch.getFarmerCanDeliver() === false"></i>
                  <i class="icon-minus3 text-muted ml-3" v-if="batch.getFarmerCanDeliver() === null"></i></span>
              </div>
            </div>
          </div>
          <br class="clearfix" />
          <div v-show="batch.hasComment()" class="row">
            <div class="col-md-4">
              <p class="text-muted"> {{ batch.getComment() }}</p>
            </div>
            <br class="clearfix"/>
          </div>
          <br class="clearfix" />
          <hr class="content-divider" />
          <BatchStatusCard v-if="canShowBatchStatusCard" :batch="batch" />
          <br class="clearfix"/>
          <Button v-if="batch.hasShipment()" @click="showShipment=true" type="eye" name="batches.edit.buttons.shipment" class="mb-2"/>
          <br class="clearfix"/>
          <BatchItemTable @showBatchOrderModal="onShowBatchOrders" :batch="batch" :actionable="batch.isReConfirmed()" :editable="isEditable" :elements="batch.getItemCollection()" :is-limited="batch.isLimited()" />
        </template>
        <template v-slot:footer>
          <div class="mr-auto">
            <Button class="mb-2 w-100 w-lg-auto" v-bind:class="{'mb-lg-0': !batch.isConfirmed()}" v-if="canUpdateBatch && !isUpdate && !batch.canShowStatusModal() && getSendButtonIconProps(batch)" v-bind:disabled="!batch.isProcessable()" @click.stop="askConfirmation(extractTypeFromBatch(batch))" :type="getSendButtonIconProps(batch).type" :name="getSendButtonIconProps(batch).message"></Button>
            <Button class="mb-2 w-100 w-lg-auto" v-bind:class="{'mb-lg-0': !batch.isConfirmed()}" v-else-if="canUpdateBatch && !isUpdate && batch.canShowStatusModal()" @click.stop="$emit('show', batch)" type="eye" name="batches.buttons.show"></Button>
            <Button class="mb-2 mb-lg-0 w-100 w-lg-auto" v-if="canUpdateBatch && !isUpdate && batch.isConfirmed()" v-bind:disabled="!batch.isProcessable()" @click.stop="askConfirmation('batches.edit.confirm.force_reconfirm')" type="modify" name="batches.edit.buttons.force_reconfirm"></Button>
          </div>
          <Button class="w-100 w-lg-auto" v-if="canUpdateBatch && batch.isQtyUpdatetableStatuses()" v-bind:disabled="!isUpdate" @click="askConfirmation('batches.edit.confirm.update')" type="confirm" name="batches.edit.buttons.update" />
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from "@/components/elements/Card";
import Button from "@/components/elements/Button";
import Alert from "@/components/elements/Alert";
import Batch from "@/entities/Batch";
import ConfirmationModal from "@/components/elements/ConfirmationModal";
import Status from "@/components/elements/Status";
import BatchItemTable from "@/components/batch/backoffice/BatchItemTable";
import Price from "@/components/elements/Price";
import ImmutabilityStatus from "@/components/elements/ImmutabilityStatus";
import BatchOrderModal from "@/components/batch/backoffice/BatchOrderModal";
import Call from "@/components/elements/Call";
import Notifiable from "@/components/elements/Notifiable";
import {LoadingModalMixin, ConfirmationModalMixin} from "@/mixins/ModalMixin";
import {AuthorizedActionsMixin, NumbersMixin, ScreenSizeMixin} from "@/mixins/GeneralMixin";
import BatchItemDetailsModal from "@/components/batch/backoffice/BatchItemDetailsModal";
import BatchStatusCard from "@/components/batch/frontoffice/BatchStatusCard";
import FarmerName from "@/components/farmer/backoffice/FarmerName";
import DayTimeEstimation from "@/components/elements/DayTimeEstimation";
import ShipmentDetailsModal from "@/components/deliverynote/ShipmentDetailsModal";
import Address from "@/components/elements/Address";

export default {
  name: "BatchEdit",
  components: {
    Address,
    DayTimeEstimation,
    FarmerName,
    BatchStatusCard,
    BatchItemDetailsModal,
    Notifiable,
    Call,
    BatchOrderModal,
    ImmutabilityStatus, Price, BatchItemTable, Status, ConfirmationModal, Alert, Card, Button, ShipmentDetailsModal},
  emits: ['update', 'show'],
  mixins: [LoadingModalMixin, ConfirmationModalMixin, ScreenSizeMixin, AuthorizedActionsMixin, NumbersMixin],
  data: function () {
    return {
      batch: null,
      item: null,
      selectedItem: null,
      showShipment: false,
    }
  },
  mounted() {
    this.getBatch();
  },
  unmounted() {
    this.$store.commit("breadcrumb", "");
  },
  computed: {
    isEditable: function () {
      return this.batch.isEditable() && !this.canShowStatusModal;
    },
    isUpdate: function () {
      return this.batch.hasChangedItems() && this.batch.isEditable();
    },
    canShowStatusModal: function () {
      return this.batch.isSentToFarm() || this.batch.isPrepacking() || this.batch.isPacking();
    },
    canShowBatchStatusCard: function () {
      return this.batch.isConfirmed() || this.batch.isReConfirmed() || this.batch.isPrepacking() || this.batch.isPacking();
    },
    activeCycle: function () {
      return this.$store.state.cycle;
    },
  },
  methods: {
    extractTypeFromBatch(batch) {
      let type = '';

      if (batch.isNew()) {
        type = 'batches.edit.confirm.confirm';
      } else if (batch.isApproved()) {
        type = 'batches.edit.confirm.send';
      } else if (batch.isConfirmed()) {
        type = 'batches.edit.confirm.prepack'
      } else if (batch.isReConfirmed()) {
        type = 'batches.edit.confirm.send_instructions';
      } else {
        console.log('Batch status is not recognized');
      }

      return type;
    },
    getSendButtonIconProps(batch)
    {
      switch (this.extractTypeFromBatch(batch)) {
        case "batches.edit.confirm.confirm":
          return {type: 'confirm', message:'batches.edit.buttons.confirm'};
        case "":
          return null;
        default:
          return {type: 'send', message:'batches.edit.buttons.send'};
      }
    },
    onLoadingComplete() {
      if (this.initialise) {
        this.getBatch();
        this.$emit('update');
      }
    },
    getBatch() {
      let that = this;
      this.queueJob();
      this.axios.get(this.$store.state.config.getBatchUri(this.$route.params.batchId)).then(
          response => {
            let content = response.data;
            that.batch = new Batch(content.data);
            that.isBatchReady(that.batch);
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.$store.commit('breadcrumb', that.batch && that.batch.getFarm().getName());
        that.completeJob();
      });
    },
    confirmed()
    {
      switch (this.confirmationType) {
        case 'batches.edit.confirm.update':
          this.updateBatch();
          break;
        case 'batches.edit.confirm.confirm':
          this.approveBatch();
          break;
        case 'batches.edit.confirm.send':
          this.sendBatch();
          break;
        case 'batches.edit.confirm.prepack':
          this.sendBatchToPrePack();
          break;
        case 'batches.edit.orders.update':
          this.updateOrders(this.confirmedEvent.getPayload());
          break;
        case 'batches.edit.confirm.send_instructions':
          this.sendBatchToPack();
          break;
        case 'batches.edit.confirm.force_reconfirm':
          this.forceReconfirmBatch();
          break;
        default:
          console.warn("Undefined confirmation type " + this.confirmationType);
      }
      this.closeConfirmation();
    },
    forceReconfirmBatch: function () {
      let that = this;
      this.queueJob();

      this.axios.put(this.$store.state.config.getBatchesForceReConfirmUri(this.$route.params.batchId), {}).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob(true);
      });
    },
    approveBatch() {
      let that = this;
      this.queueJob();

      this.axios.put(this.$store.state.config.getBatchesApproveUri(this.$route.params.batchId), {}).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob(true);
      });
    },
    updateBatch() {
      let that = this;
      this.batch.getChangedItems().forEach(function (item) {
        that.queueJob();
        that.axios.put(that.$store.state.config.getBatchesItemUpdateUri(that.batch.getId(), item.getId()), {qty: item.getQty()}).then(
            response => {
              let content = response.data;
              that.batch = new Batch(content.data);
              that.isBatchReady(that.batch);
            }
        ).catch(
            error => {
              console.warn(error);
            }
        ).finally(function () {
          that.completeJob(true);
        });
      });
    },
    sendBatch() {
      let that = this;
      this.queueJob();
      this.axios.put(that.$store.state.config.getSendBatchesToFarmUri(this.batch.getId())).then(
          response => {
            let content = response.data;
            let batch = new Batch(content.data);
            that.batch = batch;
            that.$emit('show', batch);
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob(true);
      });
    },
    sendBatchToPrePack() {
      let that = this;
      that.queueJob();
      this.axios.put(that.$store.state.config.getBatchesPrepackUri(this.batch.getId())).then(
          response => {
            let content = response.data;
            let batch = new Batch(content.data);
            that.batch = batch;
            that.$emit('show', batch);
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob(true);
      });
    },
    onShowBatchOrders(item)
    {
      if (!this.batch.isReConfirmed()) {
        this.showItemDetails(item);
        return;
      }

      if (!this.canUpdateOrdersFromBatch) {
        return;
      }

      this.item = item;
    },
    updateOrders(batchOrderItems) {
      let that = this;
      batchOrderItems.getItems().forEach(function (batchOrderItem) {
        that.queueJob();
        that.axios.put(that.$store.state.config.getOrdersItemUpdateUri(batchOrderItem.getOrderId(), batchOrderItem.getItemId()), {qty: batchOrderItem.getItemQty()}).catch(
            error => {
              console.warn(error);
            }
        ).finally(function () {
          that.completeJob(true);
        });
      });
    },
    isBatchReady(batch) {
      if (!batch.isAwaitingReadyness()) {
        return;
      }

      let that = this;
      this.queueJob();
      this.axios.get(this.$store.state.config.getBatchesReadyUri(batch.getId())).then(
          response => {
            let content = response.data;
            batch.setReadyness(content.data.ready);
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob();
      });
    },
    sendBatchToPack() {
      let that = this;
      this.queueJob();
      this.axios.put(that.$store.state.config.getBatchesPackUri(this.batch.getId())).then(
          response => {
            let content = response.data;
            let batch = new Batch(content.data);
            that.batch = batch;
            that.$emit('show', batch);
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob(true);
      });
    },
    showItemDetails(item) {
      if (this.isScreenLgWidth) {
        return;
      }
      this.selectedItem = item;
    },
    toggleNotify() {
      let that = this;
      this.batch.getFarm().setCanNotify(!this.batch.getFarm().canNotify());
      this.queueJob();
      this.axios.put(this.$store.state.config.getFarmerUri(this.batch.getFarm().getId()), {can_notify: this.batch.getFarm().canNotify()}).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob();
      });
    }
  }
}
</script>

<style scoped>
.font-size-lg-custom {
  font-size: 2em;
}
</style>

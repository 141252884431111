<template>
  <Modal :dismissible="true" @closemodal="$emit('closemodal')" :with-footer="true" :name="$t('batches.edit.orders.title', {farm: batch.getFarm().getName(), delivery: formatMomentDate(activeCycle.getCollectBatch())})">
    <Table :hoverable="false">
      <template v-slot:default>
        <BatchItemTableElement :element="item" :borderless="true" class="cursor-default" :batch="batch">
          <template v-slot:qty>
            <TableElementItem :centered="true" :borderless="true">
              <template v-slot:default>
                <Qty v-if="isMatchingQty" :amount="item.getRealQty()" class="bg-success" :unit="item.getProduct().getUnit()"/>
                <Qty v-else :amount="item.getRealQty()" class="bg-warning" :unit="item.getProduct().getUnit()"/>
              </template>
            </TableElementItem>
          </template>
        </BatchItemTableElement>
      </template>
    </Table>
    <Table>
      <template v-slot:header>
        <TableElementItem :centered="true" :is-header="true" v-for="header in headers" v-bind:key="header">
          <template v-slot:default>
            {{ $t(header) }}
          </template>
        </TableElementItem>
      </template>
      <template v-if="!isLoading && elements.getSize()">
        <TableElement v-for="element in elements.getItems()" v-bind:key="element.getContact()">
          <TableElementItem class="w-25 w-lg-auto">
            <template v-slot:default>
              <Call :telephone="element.getTelephone()" />
            </template>
          </TableElementItem>
          <TableElementItem class="w-25 w-lg-auto">
            <template v-slot:default>
              {{ element.getContact() }}
            </template>
          </TableElementItem>
          <TableElementItem>
            <template v-slot:default>
              <div class="d-lg-flex">
                <TextInput class="d-lg-inline w-lg-75" v-bind:class="!element.checkValidity() && 'error'" type="number" :reference="element" @input="changeQtyFromRealQty" :value="''+element.getRealQty()" min="0" :max="element.getInitialRealQty()"/>
                <span class="ml-1 d-block d-lg-inline align-self-lg-center w-100 pr-1 pr-lg-0 text-right w-lg-auto">{{element.getUnit()}}</span>
              </div>
              <div class="d-flex"><span class="d-inline w-100 text-right">{{ $t('batches.edit.orders.initial_qty' )}}</span><Qty element-style="text-nowrap d-inline text-left" class="p-0 ml-1" :amount="element.getInitialRealQty()" :unit="element.getUnit()" /></div>
            </template>
          </TableElementItem>
          <TableElementItem :centered="true" class="d-none d-lg-table-cell">
            <template v-slot:default>
              <Price :amount="element.getTotalPriceWithVat()" :currency="element.getCurrency()" />
            </template>
          </TableElementItem>
          <TableElementItem :centered="true" class="d-none d-lg-table-cell">
            <template v-slot:default>
              {{ element.getDeliveryEstimate() }}
            </template>
          </TableElementItem>
          <TableElementItem :centered="true" class="d-none d-lg-table-cell">
            <template v-slot:default>
              <Status :status="element.getStatus()" />
            </template>
          </TableElementItem>
          <TableElementItem :centered="true" class="d-none d-lg-table-cell">
            <template v-slot:default>
              <Notifiable :notifiable="element.canNotify()"/>
            </template>
          </TableElementItem>
        </TableElement>
      </template>
      <template v-slot:footer v-if="elements.isEmpty() || isLoading" >
        <TableElement v-if="isLoading" :size="headers.length" :loading="true" />
        <TableElement v-else :size="headers.length" :empty-message="$t('batches.edit.orders.empty')" />
      </template>
    </Table>
    <template v-slot:footer>
        <Button v-bind:disabled="!isMatchingQty" @click="askConfirmation" type="confirm" name="batches.edit.orders.confirm" class="mr-auto mb-lg-0" v-bind:class="{'mb-5':elements.getSize() > 2}"/>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/elements/Modal";
import Table from "@/components/elements/Table";
import TableElementItem from "@/components/elements/TableElementItem";
import Collection from "@/entities/Collection";
import TextInput from "@/components/elements/TextInput";
import Price from "@/components/elements/Price";
import Notifiable from "@/components/elements/Notifiable";
import TableElement from "@/components/elements/TableElement";
import Call from "@/components/elements/Call";
import Order from "@/entities/Order";
import {LoadingModalMixin} from "@/mixins/ModalMixin";
import BatchOrderItem from "@/entities/BatchOrderItem";
import Status from "@/components/elements/Status";
import Button from "@/components/elements/Button";
import EmittedEvent from "@/entities/EmittedEvent";
import BatchItemTableElement from "@/components/batch/backoffice/BatchItemTableElement";
import Qty from "@/components/elements/Qty";
import {DatesMixin, ScreenSizeMixin} from "@/mixins/GeneralMixin";

export default {
  name: "BatchOrderModal",
  components: {
    Qty,
    BatchItemTableElement,
    Button, Status, Call, TableElement, Notifiable, Price, TextInput, TableElementItem, Table, Modal},
  mixins: [LoadingModalMixin, ScreenSizeMixin, DatesMixin],
  emits: ['closemodal', 'confirmation'],
  props: {
    batch: Object,
    productId: Number,
  },
  mounted() {
    this.batch.getOrders().forEach(orderId => this.getOrder(orderId));
    setTimeout(function () {
      document.getElementsByTagName("body")[0].classList.add('modal-open');
    }, 2000);
  },
  data() {
    return {
      allHeaders: [
          'batches.edit.orders.contact',
          'batches.edit.orders.name',
          'batches.edit.orders.qty',
          'batches.edit.orders.total',
          'batches.edit.orders.delivery_estimate',
          'batches.edit.orders.status',
          '',
      ],
      responsiveHeaders: [
          'batches.edit.orders.contact',
          'batches.edit.orders.name',
          'batches.edit.orders.qty',
      ],
      elements: new Collection()
    }
  },
  computed: {
    headers: function () {
      if (this.isScreenLgWidth) {
        return this.allHeaders;
      }

      return this.responsiveHeaders;
    },
    item: function () {
      return this.batch.getItemByProductId(this.productId);
    },
    isMatchingQty: function () {
      let validity = true;
      this.elements.getItems().forEach((item) => validity &= item.checkValidity());

      return validity && this.item.getQty() === this.elements.sum(item => item.getItemQty());
    },
    activeCycle: function () {
      return this.$store.state.cycle;
    },
  },
  methods: {
    getOrder(orderId) {
      let that = this;
      this.queueJob();
      this.axios.get(this.$store.state.config.getOrderUri(orderId)).then(
          response => {
            let content = response.data;
            let order = new Order(content.data);
            let productItems = order.getItems().filter(item => item.getProduct().getId() == that.productId);
            if (productItems.length > 0) {
              that.elements.push(new BatchOrderItem(order, productItems[0]));
            }
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob();
      });
    },
    changeQtyFromRealQty(element, realQty) {
      try {
        element.isValidNewRealQty(realQty);
        let reference = element.getItem();

        if (reference.hasPacking()) {
          reference.setQty(Math.round((parseFloat(realQty) / reference.getPacking().getValue()) * 100) / 100);
          reference.setRealQty(realQty);
        } else {
          reference.setQty(Math.round(parseFloat(realQty) * 100) / 100);
          reference.setRealQty(realQty);
        }
      } catch (e) {
        console.warn(e);
      }
    },
    askConfirmation()
    {
      this.$emit('closemodal');
      this.$emit('confirmation', new EmittedEvent('batches.edit.orders.update', this.elements));
    }
  }
}
</script>

<style scoped>
.table.table-hover tbody tr.cursor-default {
  cursor: default;
}
</style>
export default class BatchOrderItem {

    orderId;
    item;
    telephone;
    contact;
    totalPriceWithVat;
    currency;
    deliveryEstimate;
    status;
    notify;
    validity = true;

    constructor(order, item) {
        this.telephone = order.getTelephone();
        this.contact = order.getContact();
        this.orderId = order.getId();
        this.item = item;
        this.totalPriceWithVat = order.getTotalPriceWithVat();
        this.currency = order.getCurrency();
        this.status = order.getStatus();
        this.notify = order.canNotify();
    }

    isValidNewRealQty(realQty)
    {
        this.validity = this.getInitialRealQty() >= realQty;
    }

    checkValidity()
    {
        return this.validity;
    }

    isBulk()
    {
        return this.item.isBulk();
    }

    getUnit()
    {
        return this.item.getUnit();
    }

    getItemId()
    {
        return this.item.getId();
    }

    getTelephone()
    {
        return this.telephone;
    }

    getContact()
    {
        return this.contact;
    }

    getRealQty()
    {
        return this.item.getRealQty();
    }

    getInitialRealQty()
    {
        return this.item.getInitialRealQty();
    }

    getTotalPriceWithVat()
    {
        return this.totalPriceWithVat;
    }

    getCurrency()
    {
        return this.currency;
    }

    getDeliveryEstimate()
    {
        return this.currency;
    }

    getStatus()
    {
        return this.status;
    }

    canNotify()
    {
        return this.notify;
    }
    getItem()
    {
        return this.item;
    }

    getOrderId()
    {
        return this.orderId;
    }

    getItemQty()
    {
        return this.item.getQty();
    }
}
<template>
  <TableElement>
    <TableElementItem :borderless="borderless">
      <template v-slot:default>
        {{ element.getProduct().getDisplayName() }}
      </template>
    </TableElementItem>
    <slot name="qty">
      <TableElementItem :borderless="borderless">
        <template v-slot:default>
          <div v-if="editable">
            <div class="d-lg-flex">
              <TextInput @click.stop :ref="'qty_'+element.getId()" :key="increment" type="number" :min="0" v-bind:class="isLimitedCheck(element) && 'error'" :max="element.getInitialRealQty()" :reference="element" @input="changeQtyFromRealQty" :value="''+element.getRealQty()"/>
              <span class="ml-1 d-block d-lg-inline align-self-lg-center w-100 text-right w-lg-auto pr-1 pr-lg-0">{{element.getProduct().getUnit()}}</span>
            </div>
            <div class="d-flex"><span class="d-inline w-100 text-right">{{ $t('batches.edit.initial_qty' )}}</span><Qty :class="{'bg-warning': element.isPartiallyConfirmed() && !batch.isDeletable(), 'bg-danger': element.isUnconfirmed() && !batch.isDeletable()}" element-style="text-nowrap d-inline text-left" class="pl-1 pr-1 pt-0 pb-0 ml-1" :amount="element.getInitialRealQty()" :unit="element.getUnit()" /></div>
          </div>
          <Qty v-else-if="element.isPartiallyConfirmed()" :amount="element.getRealQty()" class="bg-warning" :unit="element.getProduct().getUnit()"/>
          <Qty v-else-if="element.isUnconfirmed()" :amount="element.getRealQty()" class="bg-danger" :unit="element.getProduct().getUnit()"/>
          <Qty v-else :amount="element.getRealQty()" :unit="element.getProduct().getUnit()"/>
        </template>
      </TableElementItem>
    </slot>
    <TableElementItem :centered="true" :borderless="borderless" class="d-none d-lg-table-cell">
      <template v-slot:default>
        <Price :amount="element.getProduct().getSellingPriceWithVat()" :currency="element.getCurrency()" />
      </template>
    </TableElementItem>
    <TableElementItem :centered="true" :borderless="borderless" class="d-none d-lg-table-cell">
      <template v-slot:default>
        {{ $t(element.getPackingName()) }}
      </template>
    </TableElementItem>
    <TableElementItem :centered="true" :borderless="borderless" class="d-none d-lg-table-cell">
      <template v-slot:default>
        <Qty :amount="element.getQty()" />
      </template>
    </TableElementItem>
    <TableElementItem :centered="true" :borderless="borderless" class="d-none d-lg-table-cell">
      <template v-slot:default>
        <span v-if="batch.getCycle()">{{ formatMomentDate(batch.getCycle().getCollectBatch()) }}</span>
        <span v-else>-</span>
      </template>
    </TableElementItem>
    <TableElementItem :centered="true" :borderless="borderless">
      <template v-slot:default>
        <Price :amount="element.getTotalSellingPriceWithVat()" :currency="element.getCurrency()" />
      </template>
    </TableElementItem>
    <TableElementItem v-if="actionable" :centered="true" :borderless="borderless" class="d-none d-lg-table-cell">
      <template v-slot:default>
        <i v-if="element.isConfirmed()" class="icon-plus-circle2 icon-2x text-success"></i>
        <i v-else-if="element.isPartiallyConfirmed()" class="icon-plus-circle2 icon-2x text-warning"></i>
        <i v-else class="icon-plus-circle2 icon-2x text-danger"></i>
      </template>
    </TableElementItem>
  </TableElement>
</template>

<script>
import TableElement from "@/components/elements/TableElement";
import TableElementItem from "@/components/elements/TableElementItem";
import TextInput from "@/components/elements/TextInput";
import Qty from "@/components/elements/Qty";
import Price from "@/components/elements/Price";
import {DatesMixin} from "@/mixins/GeneralMixin";
export default {
  name: "BatchItemTableElement",
  components: {Price, Qty, TextInput, TableElementItem, TableElement},
  mixins: [DatesMixin],
  props: {
    element: Object,
    batch: Object,
    actionable: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    borderless: {
      type: Boolean,
      default: false
    },
    isLimited: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    activeCycle: function () {
      return this.$store.state.cycle;
    }
  },
  data: function () {
    return {
      increment: 0,
    }
  },
  methods: {
    isLimitedCheck: function (element) {
      return this.isLimited && element.getRealQty() > element.getInitialRealQty()
    },
    changeQtyFromRealQty(reference, realQty) {
      try {
        let qty = 0;

        if (realQty > reference.getInitialRealQty()) {
          realQty = reference.getInitialRealQty();
        }

        if (reference.hasPacking()) {
          qty = Math.round((parseFloat(realQty) / reference.getPacking().getValue()) * 100) / 100;
        } else {
          qty = Math.round(parseFloat(realQty) * 100) / 100;
        }

        reference.setQty(qty);
        reference.setRealQty(realQty);
        this.increment++;
        if (this.increment > 10) {
          this.increment = 0;
        }

        this.$nextTick(function () {
          this.$refs['qty_' + reference.getId()].$el.focus();
        });
      } catch (e) {
        console.warn(e);
      }
    }
  }
}
</script>

<style scoped>

</style>
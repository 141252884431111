<template>
  <Modal :dismissible="true">
    <template v-slot:default>
      <dl class="row mb-0">
        <dt class="col-4 col-md-2 mb-1 col-xl-1 font-weight-semibold">{{$t('batches.edit.details.name')}}</dt>
        <dd class="col-8 col-md-10 mb-1 col-xl-11 font-weight-semibold">
          {{item.getProduct().getDisplayName()}}
        </dd>

        <br class="clearfix"/>
        <br class="clearfix"/>

        <dt class="font-italic col-4 col-md-2 col-xl-1 font-weight-light">{{$t('batches.edit.details.item_total')}}</dt>
        <dd class="font-italic col-8 col-md-10 col-xl-11 mb-1 font-weight-light">
          <Price :amount="item.getProduct().getBuyingPriceWithVat()" :currency="item.getCurrency()" />
        </dd>
        <dt class="font-italic col-4 col-md-2 col-xl-1 font-weight-light">{{$t('batches.edit.details.packing')}}</dt>
        <dd class="font-italic col-8 col-md-10 col-xl-11 mb-1 font-weight-light">
          {{ $t(item.getPackingName()) }}
        </dd>
        <dt class="font-italic col-4 col-md-2 col-xl-1 font-weight-light">{{$t('batches.edit.details.collection_day')}}</dt>
        <dd class="font-italic col-8 col-md-10 col-xl-11 mb-1 font-weight-light">
          <DayTimeEstimation :moment="batch.getCycle().getCollectBatch()" />
        </dd>
        <dt class="font-italic col-4 col-md-2 col-xl-1 font-weight-light">{{$t('batches.edit.details.qty')}}</dt>
        <dd class="font-italic col-8 col-md-10 col-xl-11 mb-1 font-weight-light">
          <Qty elementStyle="pl-0 pr-0" :amount="item.getRealQty()" /> / #<Qty class="pl-0" elementStyle="" :amount="item.getQty()" />
        </dd>
        <dt class="font-italic col-4 col-md-2 col-xl-1 font-weight-light">{{$t('batches.edit.details.total')}}</dt>
        <dd class="font-italic col-8 col-md-10 col-xl-11 mb-1 font-weight-light">
          <Price :amount="item.getTotalSellingPriceWithVat()" :currency="item.getCurrency()" />
        </dd>
      </dl>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/elements/Modal";
import Price from "@/components/elements/Price";
import Qty from "@/components/elements/Qty";
import DayTimeEstimation from "@/components/elements/DayTimeEstimation";

export default {
  name: "BatchItemDetailsModal",
  components: {DayTimeEstimation, Qty, Modal, Price},
  props: {
    item: Object,
    batch: Object,
  },
  computed: {
    activeCycle: function () {
      return this.$store.state.cycle;
    }
  }
}
</script>

<style scoped>
</style>
<template>
  <Table>
    <template v-slot:header>
      <TableElementItem :centered="true" :is-header="true" v-for="header in headers" v-bind:key="header">
        <template v-slot:default>
          {{ $t(header) }}
        </template>
      </TableElementItem>
      <TableElementItem class="d-none d-lg-table-cell" v-if="actionable" :centered="true" :is-header="true">
        <template v-slot:default>
          {{ $t('batches.edit.actions') }}
        </template>
      </TableElementItem>
    </template>
    <template v-if="elements.getSize()">
      <BatchItemTableElement :batch="batch" v-bind:class="{'bg-warning': !isLimited && element.isQtyChanged()}" :is-limited="isLimited" @click="$emit('showBatchOrderModal', element)" v-for="element in elements.getItems()" v-bind:key="element.getId()" :element="element" :actionable="actionable" :editable="editable"/>
    </template>
    <template v-slot:footer v-if="elements.isEmpty()" >
      <TableElement :size="headers.length" :empty-message="$t('batches.edit.empty')" />
    </template>
  </Table>
</template>

<script>
import Table from "@/components/elements/Table";
import TableElementItem from "@/components/elements/TableElementItem";
import TableElement from "@/components/elements/TableElement";
import BatchItemTableElement from "@/components/batch/backoffice/BatchItemTableElement";
import {ScreenSizeMixin} from "@/mixins/GeneralMixin";

export default {
  name: "BatchItemTable",
  mixins: [ScreenSizeMixin],
  components: {BatchItemTableElement, TableElement, TableElementItem, Table},
  emits: ['showBatchOrderModal'],
  props: {
    batch: Object,
    elements: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    actionable: {
      type: Boolean,
      default: false,
    },
    isLimited: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      allHeaders: [
        'batches.edit.product',
        'batches.edit.real_quantity',
        'batches.edit.price',
        'batches.edit.packing',
        'batches.edit.quantity',
        'batches.edit.collection_day',
        'batches.edit.total_price_with_vat',
      ],
      responsiveHeaders: [
        'batches.edit.product',
        'batches.edit.real_quantity',
        'batches.edit.total_price_with_vat',
      ]
    }
  },
  computed: {
    headers: function () {
      if (this.isScreenLgWidth) {
        return this.allHeaders;
      }
      return this.responsiveHeaders;
    }
  }
}
</script>

<style scoped>

</style>